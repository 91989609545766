import React, {useEffect, useState} from "react";

import shopify from '../../../../../js/shopify';

export const CartSummary = ({ settings }) => {
  const { cart: initialCart, section, routes } = settings;
  const [ cart, setCart] = useState(initialCart);
  const cart_subtotal = cart.items_subtotal_price / 100;
 
  useEffect(() => {
    async function fetchProducts(productIds) {
      const products = await shopify.getShopifyProductList(productIds);
      return products;
    }

    // Function to fetch the cart data
    const fetchCart = async (event) => {
      let cartDataResponse: any = null;
      window.dispatchEvent(
        new CustomEvent("show-spinner", {
          detail: {
            id: "cart-spinner",
          },
        }),
      );

      try {
        const response = await fetch(`/cart.js`);
        cartDataResponse = await response.json();

        if (cartDataResponse) {
          const productIdsList = [...new Set(cartDataResponse?.items.map((product) => product.product_id))];
          const products = await fetchProducts(productIdsList);
          const updatedItems = cartDataResponse?.items.map((line_item) => {
            const product = products.find((product) => line_item.product_id === product.id);
            return product
              ? {
                  ...line_item,
                  product: product
                }
              : line_item;
          });
          const updatedCart = {
            ...cartDataResponse,
            items: updatedItems
          };
          setCart(updatedCart);
        }
      } catch (error) {
        console.error("Error fetching cart:", error);
      }

      window.dispatchEvent(
        new CustomEvent("hide-spinner", {
          detail: {
            id: "cart-spinner",
          },
        }),
      );
    };

    // Fetch cart data initially
    fetchCart({ detail: {} });

    // Set up an observer for cart changes using the Liquid Ajax Cart JS library
    document.addEventListener("liquid-ajax-cart:request-end", fetchCart);
    document.addEventListener("fetch-api-cart:request-end", fetchCart);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("liquid-ajax-cart:request-end", fetchCart);
      document.removeEventListener("fetch-api-cart:request-end", fetchCart);
    };
  }, []);

  // Calculate total savings for items with a selling plan
  const totalSavings = cart.items.reduce((acc, item) => {
    if(item?.product_type == 'Bundle') {
      const discountedPrice = item?.price / 100;
      const discountPercentage = Number(item.properties._bundleDiscount)

      if (discountedPrice) {
        const originalPrice = discountedPrice / (1 - discountPercentage / 100);
        const amountSaved = originalPrice - discountedPrice;
        return acc + amountSaved;
      }
    }
    if (item.selling_plan_allocation) {
      const originalPrice = item.selling_plan_allocation.compare_at_price; // Original price before discount
      const discountedPrice = item.selling_plan_allocation.price; // Discounted price (current price)
      const itemSavings =
        ((originalPrice - discountedPrice) / 100) * item.quantity;
      return acc + itemSavings;
    } else if (item.line_level_discount_allocations.length > 0) {
      let itemDiscount = 0;
      item.line_level_discount_allocations.forEach(discount => {
        itemDiscount += discount.amount;
      });
      const itemSavings = ((itemDiscount) / 100);
      return acc + itemSavings;
    } else {
        const originalPrice = item.product?.compare_at_price; // Original price before discount\
        if (originalPrice) {
            const discountedPrice = item.price; // Discounted price (current price)
            const itemSavings =
                ((originalPrice - discountedPrice) / 100) * item.quantity;
            return acc + itemSavings;
        }
    }
    return acc;
  }, 0);

  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

  const totalBeforeSavings = totalSavings + cart_subtotal;

  return (
    <div className="relative right-0 text-right cart__summary">
      <form
        id="cart"
        action={routes.cart_url}
        method="post"
        className="py-5 rounded cart__summary"
      >
        <div className="mb-4 space-y-6 text-left">
          <h3 className="!text-xl font-body pb-3 text-primary border-b border-tertiary/30 w-full">
            Order summary
          </h3>
          <div className="flex flex-col space-y-4 cart__summary-items">
            <div className="flex justify-between cart__summary-items-item">
              <span className="text-sm text-primary font-body">
                Subtotal
              </span>
              <span className="text-sm text-primary font-body">
                {`${currencySymbol}${totalBeforeSavings.toFixed(2)}`}
              </span>
            </div>
            {totalSavings > 0 && (
              <div className="flex justify-between cart__summary-items-item">
                <span className="text-sm text-primary font-body">
                  Savings
                </span>
                <div className="font-body text-sm text-red-500">
                  -{currencySymbol}{totalSavings.toFixed(2)}
                </div>
              </div>
            )}
            <div className="flex justify-between cart__summary-items-item">
              <span className="text-sm text-primary font-body">
                Shipping
              </span>
              <span className="text-sm text-primary font-body">
                Calculated at checkout
              </span>
            </div>
            <div className="flex justify-between cart__summary-items-item">
              <span className="text-xl font-semibold text-primary font-body">
                Total
              </span>
              <span className="text-xl font-semibold text-primary font-body">{currencySymbol}{(cart.items_subtotal_price / 100).toFixed(2)}</span>
            </div>
          </div>

          <div className="cart__summary-items flex flex-col space-y-[24px] hidden">
            <div className="flex justify-between cart__summary-items-item">
              <span>Number of items</span>
              <span>{cart.item_count}</span>
            </div>
          </div>

          {section.summary_message && (
            <div className="text-sm">{section.summary_message}</div>
          )}
        </div>

        {section.show_cart_note && (
          <>
            <hr />
            <div className="my-2 cart_notes">
              <span className="mt-2">Cart Notes</span>
              <input
                className="w-full py-2 bg-white border text-primary border-tertiary/30 indent-2"
                type="text"
                name="cart_note"
                placeholder="Enter notes for cart"
              />
            </div>
          </>
        )}

        <button
          className="w-full btn-primary"
          type="submit"
          name="checkout"
          form="checkout"
        >
          Checkout
        </button>
      </form>
    </div>
  );
};
