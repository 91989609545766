import VideoContainer from "@mindarc/arctheme-components/frontend/src/stories/components/sections/VideoContainer/VideoContainer";
import { Testimonials } from "@mindarc/arctheme-components/frontend/src/stories/components/sections/Testimonials/Testimonials";
import { CollectionFilter } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Filters/Filters";
import Logo from "@mindarc/arctheme-components/frontend/src/stories/components/sections/Logo/Logo";
import Marquee from "@mindarc/arctheme-components/frontend/src/stories/components/sections/Marquee/Marquee";
import { FAQ } from "../src/stories/sections/FAQ/FAQ";

import CategoryBanner from "../src/stories/components/CategoryBanner/CategoryBanner.tsx";
import CollectionHeader from "../src/stories/components/CollectionHeader/CollectionHeader.tsx";
import CollectionBanner from "../src/stories/components/CollectionBanner/CollectionBanner.tsx";

import QuickLinks from "../src/stories/sections/QuickLinks/QuickLinks";
import TemperatureGuide from "../src/stories/sections/TemperatureGuide/TemperatureGuide";
import LogoList from "../src/stories/sections/LogoList/LogoList";
import ImageHotSpot from "../src/stories/sections/ImageHotSpot/ImageHotSpot";
import TabbedWithImage from "../src/stories/sections/TabbedWithImage/TabbedWithImage";
import FeaturedCollections from "../src/stories/sections/FeaturedCollections/FeaturedCollections";
import ImageCompare from "../src/stories/sections/ImageCompare/ImageCompare";
import Title from "../src/stories/sections/Title/Title";
import PromoBanner from "../src/stories/sections/PromoBanner/PromoBanner";

import { mobileBreakpoint } from "../entrypoints/theme";

// ALPINE JS
import newsletter from "../js/alpine/components/newsletter";
import ExtendedBanner from "../src/stories/sections/ExtendedBanner/Banner";
import ExtendedNewsletterSignup from "../src/stories/components/NewsletterSignup/ExtendedNewsletterSignup";
import {TextWithMedia} from "@stories/sections/TextWithMedia/TextWithMedia";
import {Table} from "@stories/sections/Table/Table";
import Multicolumn from "@project-stories/sections/Multicolumn/Multicolumn";
import {Gallery} from "@stories/sections/Gallery/Gallery";

export function MulticolumnBlockSection({ ...props }) {
  return (
      <Multicolumn
          settings={props.settings}
          title={props.settings.section.title}
          description={props.settings.section.description}
          columns={props.settings.blocks}
          additional={props.settings.additional}
      />
  );
}
export function VideoContainerSection({ ...props }) {
  return (
    <VideoContainer
      containerTitle={props.settings.section.title}
      settings={props.settings.section}
      videos={props.settings.blocks}
    />
  );
}

export function MarqueeSection({ ...props }) {
  return <Marquee marquee_blocks={props.settings.blocks} />;
}

export function FAQSection({ ...props }) {
  props.settings.section.mobile_breakpoint = mobileBreakpoint;
  return (
    <FAQ
      settings={props.settings.section}
      blocks={props.settings.blocks}
      faqs={props.settings.faqs}
    />
  );
}


export function SectionTextWithMedia({ ...props }) {
  return (
      <TextWithMedia
          reverselayout={false}
          settings={props.settings.section}
          blocks={props.settings.blocks}
          product={props.settings.product}
          additional={props.settings.additional}
      />
  );
}

export function SectionTable({ ...props }) {
  props.settings.section.mobile_breakpoint = mobileBreakpoint;
  return <Table settings={props.settings} />;
}
export function SectionGallery({ ...props }) {
  props.settings.section.mobile_breakpoint = mobileBreakpoint;
  return <Gallery settings={props.settings} />;
}

export function SectionTestimonials({ ...props }) {
  return (
    <Testimonials
      title={props.settings.section.title}
      subtext={props.settings.section.subtext}
      reviews={props.settings.blocks}
    />
  );
}

export function SectionNewsletterSubscribe({ ...props }) {
  async function customerSubscribe(email) {
    console.log("Subscribing Customer: " + email);
    await newsletter.component().subscribe(email);
  }

  return (
    <ExtendedNewsletterSignup
      show_button={true}
      heading={props.settings.section.cta_title}
      content={props.settings.section.content}
      button_text={props.settings.section.button_text}
      cta_text={props.settings.section.cta_text}
      enable_newsletter_form={props.settings.section.enable_newsletter_form}
      redirect_url={props.settings.section.redirect_url}
      image={props.settings.section.image}
      onClick={customerSubscribe}
    />
  );
}

export function SectionBanner({ ...props }) {
  return (
    <ExtendedBanner
      banners={props.settings.blocks}
      section={props.settings.section}
      useCarousel={props.settings.section.use_carousel}
    />
  );
}

export function SectionCategoryBanner({ ...props }) {
  return <CategoryBanner settings={props.settings} />
}

export function SectionCollectionHeader({ ...props }) {
  return <CollectionHeader settings={props.settings} />
}

export function SectionCollectionBanner({ ...props }) {
  return <CollectionBanner settings={props.settings} />
}

export function CollectionFilters({ ...props }) {
  return (
    <CollectionFilter
      title={props.settings.section.title}
      filters={props.settings.blocks}
    />
  )
}

export function QuickLinksSection({ ...props }) {
  return (
    <QuickLinks
      title={props.settings.section.title}
      description={props.settings.section.description}
      quicklinks={props.settings.blocks}
    />
  )
}

export function TemperatureGuideSection({ ...props }) {
  return (
    <TemperatureGuide
      settings={props.settings.section}
      tog_block={props.settings.blocks}
    />
  )
}

export function LogoListSection({ ...props }) {
  return (
    <LogoList
      title={props.settings.section.title}
      logo_block={props.settings.blocks}
    />
  )
}

export function ImageHotSpotSection({ ...props }) {
  return (
    <ImageHotSpot
      settings={props.settings.section}
      hotspot_block={props.settings.blocks}
      additional={props.settings.additional}
    />
  )
}

export function TabbedWithImageSection({ ...props }) {
  return (
    <TabbedWithImage
      settings={props.settings.section}
      blocks={props.settings.blocks}
    />
  )
}

export function FeaturedCollectionsSection({ ...props }) {
  return (
    <FeaturedCollections
      settings={props.settings.section}
      blocks={props.settings.blocks}
      product_id={props?.settings?.product?.id}
    />
  )
}

export function ImageCompareSection({ ...props }) {
  return (
    <ImageCompare
      settings={props.settings.section}
      additional={props.settings.additional}
    />
  )
}

export function TitleSection({ ...props }) {
  return (
    <Title
      settings={props.settings}
      title={props.settings.section.title}
      text_alignment={props.settings.section.text_alignment}
    />
  )
}

export function PromoBannerSection({ ...props }) {
  return (
    <PromoBanner settings={props.settings.section} />
  )
}

export function SectionFeaturedCompanies({ ...props }) {
  return (
    <Logo
      title={props.settings.section.title}
      logos={props.settings.blocks}
      use_greyscale={props.settings.section.use_greyscale}
    />
  );
}
