import React from "react";
import { CartProductOptions } from "./CartProductOptions";
import ExtendedImage from "../../../elements/ExtendedImage/ExtendedImage";

export const CartTableProduct = ({ item, bundle = false }) => {
  if (!item) {
    return false;
  }

  const renderBundleComponents = (line_item) => {
    const bundle_components = line_item && line_item.properties._compiledItems?.split(',');
    return bundle_components && bundle_components.map((component, index) => {
      const labels = component.split('|');
      const title = labels.length > 0 && labels[0];
      const options = labels.length > 0 && labels[1];
      const sizeLabel = options ? options.split('/').at(-1) : null;
      return (
        <div key={`${labels}${index}-${line_item.key}`} className="flex flex-col  border-b border-[#F5F5F5] mb-1 ">
            <span className="text-sm font-body text-tertiary " key={component}>
              {`${index+1}. ${title}`}
            </span>
            <div> 
              <span className="text-sm font-body text-tertiary" key={component}>
                {`Size: `}
              </span>
              <span className="text-sm font-semibold text-tertiary" key={component}>
                {`${sizeLabel}`}
              </span>
            </div>
        </div>
      )
    }
   
    );
  };

  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

  return (
    <>
      <div className="cart-table__line-item-image">
        <a href={item.product_type == 'Bundle' ? item.properties._bundleURL : item.url}>
          <ExtendedImage
            classes={"item__image"}
            imageUrl={item.product_type == 'Bundle' ? item.properties._bundleImage :item.image}
            alt={item.title}
            isLazy={true}
            />
        </a>
      </div>
      <div className="cart-table__line-item-details">
        <div className="flex flex-col product-details">
          <div className="text-sm font-semibold font-body text-primary">
            {item.product_type == 'Bundle' ? item.title : item.product_title}
          </div>

          <div className="text-sm font-semibold font-body text-tertiary">
            {currencySymbol}{item.price / 100}
          </div>

          {item.selling_plan_allocation && (
            <div className="product-selling-plan b3">
              {item.selling_plan_allocation.selling_plan.name}
            </div>
          )}

          { !bundle && 
          <div className="text-sm product-options font-body text-tertiary">
            {CartProductOptions(item.options_with_values)}
          </div>
          }


          { bundle && 
          <div className="text-sm product-options font-body text-tertiary">
            {renderBundleComponents(item)}
          </div>
          }   
          

          <div
            className="text-xs font-body text-[#F8624E] mt-1 lg:mt-0"
            data-ajax-cart-errors={`${item.key}`}
          ></div>

        </div>
      </div>
    </>
  );
};
