export default {
  name: "price",
  component() {
    return {
      selectedPrice: "0",
      selectedCompareAtPrice: "0",
      selectedDiscountPercent: null,
      selectedDiscountAmount: null,

      init(price, comparePrice) {
        this.selectedPrice = price;
        this.selectedCompareAtPrice = comparePrice;
        this.calculateDiscount();

        this.$watch("selectedPrice", () => this.calculateDiscount());
        this.$watch("selectedCompareAtPrice", () => this.calculateDiscount());

        const updatePriceHandler = (event) => this.updatePrice(event.detail);
        window.addEventListener("update-price", updatePriceHandler);

        this._updatePriceHandler = updatePriceHandler;
      },

      updatePrice(detail) {
        console.log("Received event:", detail);

        this.selectedPrice = detail.price;
        this.selectedCompareAtPrice = detail.comparePrice;
      },

      calculateDiscount() {
        let price = parseFloat(this.selectedPrice.replace(/[^0-9.]/g, "")) || 0;
        let comparePrice = parseFloat(this.selectedCompareAtPrice.replace(/[^0-9.]/g, "")) || 0;
        this.selectedDiscountAmount =  Number(comparePrice - price).toFixed(2);
    
        if (comparePrice > price) {
          this.selectedDiscountPercent = Math.round(((comparePrice - price) / comparePrice) * 100);
        } else {
          this.selectedDiscountPercent = null;
        }
      },

      destroy() {
        if (this._updatePriceHandler) {
          window.removeEventListener("update-price", this._updatePriceHandler);
        }
      },
    };
  },
};