import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import { addToCart } from "frontend/js/useShopifyCart";

export const ExtendedBundleAddToCart = ({ settings }) => {

   const { button_style, show_price, adding_message } = settings;
   const [details, setDetails] = useState({});
   const [isLoading, setIsLoading ] = useState(false);
   const [buttonLabel, setButtonLabel ] = useState("");
   const [disabled, setDisabled] = useState(false);
   console.log(settings);
   const handleData = useCallback((event) => {
    console.log(event, "event");
    console.log(event.detail, "event details"); // Accessing event details
    setDetails(event.detail);
    const currPrice = Number((Number(event.detail.price) / 100).toFixed(2));
    const reducedNumber = currPrice - (currPrice * (Number(event.detail.bundleDiscount) / 100));
    setDisabled(event.detail.disableAdd);
    setButtonLabel(show_price ? `Add to Bag | ${currencySymbol}${Number(reducedNumber.toFixed(2))}` :"Add to Bag" )
  }, []);

  const handleLoading = useCallback((event) => {
    setIsLoading(false);
  }, []);

  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

  async function handleAddToCart() {
   // Create a unique key
    const uniqueId = `_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    const bulkItems = details.variantIds && Object.values(details.variantIds).map((variant) => {
      return ( {
        id: Number(variant.id),
        quantity: 1,
        properties: {
          "_bundleDiscount": details?.bundleDiscount,
          "_bundleId": details?.bundleId && `${details?.bundleId}${uniqueId}`,         
          "_bundleName": details?.bundleName,
          "_bundleImage": details?.bundleImage,
          "_bundleURL": details?.bundleURL,
          "_parentBundleId": `gid://shopify/ProductVariant/${details?.parentBundleId}`
        }
      })
    })
    setIsLoading(true);

    await addToCart({ items: bulkItems });
  }

  useEffect(() => {
    document.addEventListener("bundle-update", handleData);
    document.addEventListener("fetch-api-cart:request-end", handleLoading);
    return () => {
      document.removeEventListener("bundle-update", handleData);
      document.removeEventListener("fetch-api-cart:request-end", handleLoading);
    };
  }, [handleData]);

  return (
    <div className="flex flex-col">
        { disabled && 
            <div className="font-body text-md text-body text-[#B42318] my-3">
              Finish size selection to order 
            </div>}
    <button disabled={disabled} onClick={() => handleAddToCart()} className={`product-bundle ${button_style} ${disabled ? "!bg-[#7B7B7B] !border-[#7B7B7B]" : "" } cursor-pointer w-full flex-auto ${isLoading ? "!bg-white focus:bg-white active:bg-white": '' }`}>
        <span className={`${isLoading ? 'hidden': 'flex'}  flex-row items-center justify-center`}>
            { buttonLabel }
        </span>
        <span className={`${isLoading ? 'flex' : 'hidden'} flex-row items-center justify-center`}>
            <div className="mt-1 mr-1"> <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </div>
            <div className="font-body">{ adding_message }</div>
          </span>
    </button>
    </div>
  
  );
};

export default ExtendedBundleAddToCart;

