import React from "react";
import ExtendedImage from "../../../elements/ExtendedImage/ExtendedImage";
import { updateItem, updateItems, addToCart } from "frontend/js/useShopifyCart";

export const CartItems = ({
  items,
  show_vendor = false
}) => {
  const AjaxCartQuantity = "ajax-cart-quantity" as keyof JSX.IntrinsicElements;
  const showItemDivider = false;
  const showOptionNames = true;
  const siteUsesSubscriptions = false;

  // Filter items into one-time purchases and subscriptions
  const oneTimePurchaseItems = items.filter(
    (item) => !item.selling_plan_allocation  && !item.properties._bundleId,
  );
  const subscriptionItems = items.filter(
    (item) => item.selling_plan_allocation && !item.properties._bundleId,
  );

  const nonBundleItems = items.filter(
    (item) => item.product_type != 'Bundle',
  );

  const bundleItems = items.filter(
    (item) =>  item.product_type == 'Bundle',
  ).reduce((acc, item) => {
    const bundleName = item.properties?._bundleName || null;
    const compiledItems = item.properties?._compiledItems || null;

    if (bundleName && compiledItems) {
        const groupKey = `${bundleName}|${compiledItems}`;

        if (!acc[groupKey]) {
            acc[groupKey] = {
                ...item,
                quantity: 0,
                groupedItems: []
            };
        }

        acc[groupKey].quantity += item.quantity;
        acc[groupKey].groupedItems.push(item);
    } else {
        acc[`${item.id}`] = item; 
    }

    return acc;
}, {});

const compiledBundleItems = Object.values(bundleItems);


const renderBundleComponents = (line_item) => {
    const bundle_components = line_item && line_item.properties._compiledItems?.split(',');
   
    return bundle_components && bundle_components.map((component, index) => {
      const labels = component.split('|');
      const title = labels.length > 0 && labels[0];
      const options = labels.length > 0 && labels[1];
      const sizeLabel = options ? options.split('/').at(-1) : null;
      return (
        <div key={`${labels}${index}-${line_item.key}`} className="flex flex-col  border-b border-[#F5F5F5] mb-1">
            <span className="text-sm font-body text-tertiary " key={component}>
              {`${index+1}. ${title}`}
            </span>
            <div> 
              <span className="text-sm font-body text-tertiary" key={component}>
                {`Size: `}
              </span>
              <span className="text-sm font-semibold font-body text-tertiary" key={component}>
                {`${sizeLabel}`}
              </span>
            </div>
         
        </div>
      )
    }
   
    );
  };

  async function handleRemove(line_item) {
    console.log(line_item);
   
    const updates = {}
    line_item.groupedItems.forEach(item => {
      updates[`${item.key}`] = 0;
    })

    await updateItems({ updates });

  }

  async function handleQuantityUpdate(line_item, increase) {
    console.log(line_item);
    if(increase) {
      const idsArray = line_item && line_item.properties._compiledIds.split(", ") .map(id => id.split("/").pop()); 
      const uniqueId = `_${Math.random().toString(36).substr(2, 9)}`;  
      const bulkItems = idsArray && Object.values(idsArray).map((item) => {
        return ( {
          id: Number(item),
          quantity: 1,
          properties: {
            "_bundleDiscount": line_item.properties && line_item.properties._bundleDiscount,
            "_bundleId": line_item.properties && line_item.properties._bundleId && `${line_item.properties._bundleId}${uniqueId}`,      
            "_bundleName": line_item.properties && line_item.properties._bundleName,
            "_bundleImage": line_item.properties && line_item.properties._bundleImage,
            "_bundleURL": line_item.properties && line_item.properties._bundleURL,
            "_parentBundleId": `gid://shopify/ProductVariant/${line_item.variant_id}`
          }
        })
      })
      await addToCart({ items: bulkItems });
    }
    else {
      const lineItemToBeDeleted = line_item.groupedItems[0]?.key
      await updateItem({ id: lineItemToBeDeleted, quantity: 0});
    }
  }


  const renderLineItem = (line_item, index) => {
    const line_item_price = line_item.price / 100;
    const line_item_quantity = line_item.quantity;
    const indexLineItem = index + 1;
    let originalBundlePrice = 0;


    if (line_item.product_type == 'Bundle') {
      const discountedBundlePrice = line_item?.price / 100;
      const discountPercentage = Number(line_item.properties._bundleDiscount);
      originalBundlePrice = discountedBundlePrice / (1 - discountPercentage / 100);
    }
 
    const size = line_item.options_with_values.find(
      (option) => option.name.toLowerCase() === "size",
    );
    const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

    console.log('Currency', currencySymbol);
    return (
      <React.Fragment key={index}>
        <li
          data-id={indexLineItem}
          id={`${line_item.id}`}
          data-line={indexLineItem}
          key={line_item.id}
          className="item"
        >
          
          <a href={line_item.product_type == 'Bundle' ? line_item.properties._bundleURL : line_item.url} className="basis-1/3">
            <ExtendedImage
              classes={"item__image"}
              imageUrl={line_item.product_type == 'Bundle' ? line_item.properties._bundleImage : line_item.image}
              alt={line_item.product_title}
              isLazy={true}
              />
          </a>

          <div className="item-details basis-8/12">
            <div className="flex flex-col justify-center gap-1">
              <a href={line_item.product_type == 'Bundle' ? line_item.properties._bundleURL : line_item.url} >
                {line_item.product_type == 'Bundle' && 
                  (
                  <h4 className="!text-sm font-body text-primary hover:text-highlight">
                    {line_item.title}
                  </h4>
                  )
                }
                {line_item.product_type != 'Bundle'&& 
                <h4 className="!text-sm font-body text-primary hover:text-highlight">
                  {line_item.product_title}
                </h4> }
              </a>
                
              <span className="text-sm font-body text-tertiary">
                {
                  line_item.options_with_values.find(
                    (option) => option.name.toLowerCase() === "colour",
                  )?.value
                }
              </span>

              {size && (
                <div className="flex">
                  <span className={`option`} data-option-name={size?.name}>
                    {showOptionNames && (
                      <span className="text-sm font-body text-tertiary">{size?.name}: </span>
                    )}
                    <span className="text-sm font-semibold font-body text-tertiary">{size?.value}</span>
                  </span>
                </div>
              )}

              {line_item.product_type == 'Bundle' && renderBundleComponents(line_item)}

              <div className="flex">
                  {
                    (line_item.product_type == 'Bundle' || line_item.product?.compare_at_price) && (
                    <div className="flex gap-3">
                      <span className="text-sm text-red-500 font-body">
                        {currencySymbol}{(line_item_price * line_item.quantity).toFixed(2)}
                      </span>
                
                      <span className="text-sm line-through text-tertiary   font-body">
                          {currencySymbol}
                          { line_item.product_type == 'Bundle' ? (originalBundlePrice * line_item.quantity).toFixed(2) : 
                          ((line_item.product.compare_at_price / 100) *
                            line_item.quantity).toFixed(2)
                            
                            }
                      </span>
                    </div>
                    )
                  }
                  
                   {!line_item.product?.compare_at_price && line_item.product_type != 'Bundle'  && (
                      <span className="text-sm text-tertiary font-body">
                        {currencySymbol}{(line_item_price * line_item.quantity).toFixed(2)}
                      </span>
                  )}
              </div>
            </div>

            {show_vendor && (
              <small className={`item-vendor`}>{line_item.vendor}</small>
            )}

            <div
              className={`flex items-center justify-between quantity-selector gap-4 mt-3`}
            >
              { line_item.product_type == 'Bundle'&&
                   <div className="flex items-center w-full h-8 border max-w-[100px] rounded-xl border-tertiary">
                    <button
                        className="w-auto h-auto px-2 border-none"
                        onClick={() => handleQuantityUpdate(line_item, false)}
                    >
                      <span className="text-xs text-primary">
                        <svg fill="#323D3E" width="12px" height="12px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                          <path d="M216,132H40a4,4,0,0,1,0-8H216a4,4,0,0,1,0,8Z" fill="#323D3E" />
                        </svg>
                      </span>
                    </button>

                     <input
                      value={line_item_quantity}
                      type="number"
                      className="!pb-1 quantity-control-input !text-xs text-tertiary !bg-transparent"
                      readOnly={true}
                    />
                
                    <button
                      className="w-auto h-auto px-2 border-none"
                      onClick={() => handleQuantityUpdate(line_item, true)}
                    >
                      <span className="text-xs icon-plus text-tertiary"></span>
                    </button>
                  </div>
              }
              {line_item.product_type != 'Bundle'&& 
                <AjaxCartQuantity>
                  <div className="flex items-center w-full h-8 border max-w-[100px] rounded-xl border-tertiary">
                    <button
                      data-ajax-cart-quantity-minus
                      className="w-auto h-auto px-2 border-none"
                    >
                      <span className="text-xs text-primary">
                        <svg fill="#323D3E" width="12px" height="12px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                          <path d="M216,132H40a4,4,0,0,1,0-8H216a4,4,0,0,1,0,8Z" fill="#323D3E" />
                        </svg>
                      </span>
                    </button>
                    <input
                      data-ajax-cart-quantity-input={`${indexLineItem}`}
                      value={line_item_quantity}
                      type="number"
                      className="!pb-1 quantity-control-input !text-xs text-tertiary !bg-transparent"
                      data-ajax-cart-static-element
                      readOnly={true}
                    />
                    <button
                      data-ajax-cart-quantity-plus
                      className="w-auto h-auto px-2 border-none"
                    >
                      <span className="text-xs icon-plus text-tertiary"></span>
                    </button>
                  </div>
                </AjaxCartQuantity>
              }
              {line_item.product_type != 'Bundle'&& 
              <a
                className={`item__remove text-xs underline text-tertiary font-body hover:text-highlight`}
                data-ajax-cart-request-button={`/cart/change?id=${line_item.key}&quantity=0`}
              >
                Remove
              </a>
              }
             {line_item.product_type == 'Bundle'&& 
              <a
                className={`item__remove text-xs underline text-tertiary font-body hover:text-highlight`}
                onClick={() => handleRemove(line_item)}
              >
                Remove
              </a>
              }
            </div>

            <div
              className="flex text-xs !bg-transparent errors font-body !text-left !text-[#F8624E]"
              style={{ padding: 0 }}
              data-ajax-cart-errors={`${line_item.key}`}
            ></div>

          </div>
        </li>
        {showItemDivider && <hr className="border-none h-[1px] bg-[#ECEEEF]" />}
      </React.Fragment>
    );
  };

  return (
    <div className={"relative"}>
      {items.length > 0 && (
        <>
          {siteUsesSubscriptions && subscriptionItems.length > 0 && (
            <ul
              id="subscription-items"
              className="flex flex-col flex-grow px-4 items-list"
            >
              <h2 className={"b3__regular"}>Subscription products</h2>
              {subscriptionItems.map((item, index) =>
                renderLineItem(item, index),
              )}
            </ul>
          )}

          {siteUsesSubscriptions && oneTimePurchaseItems.length > 0 && (
            <ul
              id="one-time-purchase-items"
              className="flex flex-col flex-grow px-4 items-list"
            >
              <h2 className={"b3__regular"}>One time purchase</h2>
              {oneTimePurchaseItems.map((item, index) =>
                renderLineItem(item, index),
              )}
              {compiledBundleItems && compiledBundleItems.map((item, index) => renderLineItem(item, index))}
            </ul>
          )}

          {!siteUsesSubscriptions &&  items.length > 0 && (
            <ul
              id="one-time-purchase-items"
              className="flex flex-col flex-grow px-4 items-list lg:px-8"
            >
              {nonBundleItems.map((item, index) => renderLineItem(item, index))}
              {compiledBundleItems && compiledBundleItems.map((item, index) => renderLineItem(item, index))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};
