import * as React from "react";
import { useState, useEffect } from "react";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import './LogoList.scss';

export const LogoList = ({ title, logo_block }) => {
  const [hoveredLogo, setHoveredLogo] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (logo_block.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % logo_block.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [logo_block.length]);

  useEffect(() => {
    if (logo_block.length > 0) {
      setHoveredLogo(logo_block[currentIndex]);
    }
  }, [currentIndex, logo_block]);

  return (
    <div className="text-center">
      <span className="block mb-8 italic font-medium text-base/4 font-display lg:text-xl/4">
        {title}
      </span>
      {hoveredLogo && (
        <ExtendedHeadings
          text={hoveredLogo.settings.quote}
          type={"h3"}
          classes={"h3 font-bold min-h-[100px] mx-auto lg:min-h-[232px] lg:max-w-md"}
        />
      )}
      <div className="flex flex-wrap items-center justify-center lg:space-x-9">
        {logo_block.map((logo, index) => (
          <div
            key={logo.key || index}
            onMouseEnter={() => setHoveredLogo(logo)}
            onMouseLeave={() => setHoveredLogo(logo_block[currentIndex])}
            className="flex items-center justify-center basis-1/2 mb-7 lg:mb-0 lg:basis-auto"
          >
            {logo.settings.link ? (
              <a
                href={logo.settings.link}
                rel="noopener noreferrer"
              >
                <ExtendedImage
                  imageUrl={logo.responsive_image_url ? logo.responsive_image_url.src : logo.settings.image}
                  imageUrlSet={logo.responsive_image_url ? logo.responsive_image_url.srcset : ''}
                  alt={logo.settings.description}
                  isLazy={true}
                  classes={'h-[60px]'} 
                />
              </a>
            ) : (
              <ExtendedImage
                imageUrl={logo.responsive_image_url ? logo.responsive_image_url.src : logo.settings.image}
                imageUrlSet={logo.responsive_image_url ? logo.responsive_image_url.srcset : ''}
                alt={logo.settings.description}
                isLazy={true}
                classes={'h-[60px]'} 
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoList;