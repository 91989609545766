import React from "react";
import { Paragraph } from "@arctheme-components/elements/Paragraph/Paragraph";

export const CartTotals = ({ cart_subtotal, cart_items, translations  }) => {
  // Calculate total savings for items with a selling plan
  const totalSavings = cart_items.reduce((acc, item) => {
    if(item?.product_type == 'Bundle') {
      const discountedPrice = item?.price / 100;
      const discountPercentage = Number(item.properties._bundleDiscount)

      if (discountedPrice) {
        const originalPrice = discountedPrice / (1 - discountPercentage / 100);
        const amountSaved = originalPrice - discountedPrice;
        return acc + amountSaved;
      }
    }
    if (item.selling_plan_allocation) {
      const originalPrice = item.selling_plan_allocation.compare_at_price; // Original price before discount
      const discountedPrice = item.selling_plan_allocation.price; // Discounted price (current price)
      const itemSavings =
        ((originalPrice - discountedPrice) / 100) * item.quantity;
      return acc + itemSavings;
    } else if (item.line_level_discount_allocations.length > 0) {
      let itemDiscount = 0;
      item.line_level_discount_allocations.forEach(discount => {
        itemDiscount += discount.amount;
      });
      const itemSavings = ((itemDiscount) / 100);
      return acc + itemSavings;
    } else {
        const originalPrice = item.product?.compare_at_price; // Original price before discount\
        if (originalPrice) {
            const discountedPrice = item.price; // Discounted price (current price)
            const itemSavings =
                ((originalPrice - discountedPrice) / 100) * item.quantity;
            return acc + itemSavings;
        }
    }
    return acc;
  }, 0);



  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

  const totalBeforeSavings = totalSavings + cart_subtotal;


  return (
    <div className={"py-[8px]"}>
      <div className="minicart__totals">
        <Paragraph text={translations?.subtotal || 'Subtotal'} type={"text-sm font-body text-primary font-semibold"} />
        <Paragraph text={`${currencySymbol}${totalBeforeSavings.toFixed(2)}`} type="font-body text-sm text-primary" />
      </div>
      {totalSavings > 0 && (
        <div className="minicart__totals">
          <Paragraph
            text={translations?.savings || 'Savings'}
            type={"text-sm font-body text-primary font-semibold"}
          />
          <Paragraph text={`-${currencySymbol}${totalSavings.toFixed(2)}`} type="font-body text-sm text-primary text-red-500" />
        </div>
      )}
      <div className="minicart__totals">
        <Paragraph  text={translations?.shipping || 'Shipping'} type={"text-sm font-body text-primary font-semibold"} />
        <div className={"flex flex-col"}>
          <Paragraph
            text={`Calculated at checkout`}
            type={"text-primary text-sm font-body"}
          />
        </div>
      </div>
      <div className="minicart__totals">
        <Paragraph text={translations?.total || 'Total'} type={"text-sm font-body text-primary font-semibold"} />
        <Paragraph text={`${currencySymbol}${cart_subtotal.toFixed(2)}`} type="font-body text-sm text-primary" />
      </div>
    </div>
  );
};
