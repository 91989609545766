import React from "react";
import { updateItem, updateItems, addToCart } from "frontend/js/useShopifyCart";

export const CartProductQuantity = ({ item, settings, bundle = false }) => {
  const { routes } = settings;
  const AjaxCartQuantity = "ajax-cart-quantity" as keyof JSX.IntrinsicElements;
  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();

  let originalBundlePrice = 0;

  if (item.product_type == 'Bundle') {
    const discountedBundlePrice = item?.price / 100;
    const discountPercentage = Number(item.properties._bundleDiscount);
    originalBundlePrice = discountedBundlePrice / (1 - discountPercentage / 100);
  }

  async function handleQuantityUpdate(line_item, increase) {
    if(increase) {
      const idsArray = line_item && line_item.properties._compiledIds.split(", ") .map(id => id.split("/").pop()); 
      const uniqueId = `_${Math.random().toString(36).substr(2, 9)}`;  
      const bulkItems = idsArray && Object.values(idsArray).map((item) => {
        return ( {
          id: Number(item),
          quantity: 1,
          properties: {
            "_bundleDiscount": line_item.properties && line_item.properties._bundleDiscount,
            "_bundleId": line_item.properties && line_item.properties._bundleId && `${line_item.properties._bundleId}${uniqueId}`,      
            "_bundleName": line_item.properties && line_item.properties._bundleName,
            "_bundleImage": line_item.properties && line_item.properties._bundleImage,
            "_bundleURL": line_item.properties && line_item.properties._bundleURL,
            "_parentBundleId": `gid://shopify/ProductVariant/${line_item.variant_id}`
          }
        })
      })
      await addToCart({ items: bulkItems }, true , false);
    }
    else {
      const lineItemToBeDeleted = line_item.groupedItems[0]?.key
      await updateItem({ id: lineItemToBeDeleted, quantity: 0});
    }
  }

  if (bundle) {
    return (
      <>
      <div className="cart-table__line-item-qty">
        <div className="flex items-center w-full h-8 border max-w-[100px] rounded-xl border-tertiary">
        <button
            className="w-auto h-auto px-2 border-none"
            onClick={() => handleQuantityUpdate(item, false)}
        >
          <span className="text-xs text-primary">
            <svg fill="#323D3E" width="12px" height="12px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
              <path d="M216,132H40a4,4,0,0,1,0-8H216a4,4,0,0,1,0,8Z" fill="#323D3E" />
            </svg>
          </span>
        </button>

        <input
          value={item.quantity}
          type="number"
          className="!pb-1 quantity-control-input !text-xs text-tertiary !bg-transparent"
          readOnly={true}
        />
    
        <button
          className="w-auto h-auto px-2 border-none"
          onClick={() => handleQuantityUpdate(item, true)}
        >
          <span className="text-xs icon-plus text-tertiary"></span>
        </button>

        
        </div>
      </div>
            
      <div className="flex gap-3">
        <span className="text-sm text-red-500 font-body">
          {currencySymbol}{(item.price / 100 * item.quantity).toFixed(2)}
        </span>
  
        <span className="text-sm line-through text-tertiary   font-body">
            {currencySymbol}
            { item.product_type == 'Bundle' ?  (originalBundlePrice * item.quantity).toFixed(2) : (item.product?.compare_at_price / 100) *
              item.quantity}
        </span>
      </div>
         
      </>
    
    )
  }
  return (
    <>
      <div className="cart-table__line-item-qty">

        <AjaxCartQuantity>
          <div className="flex items-center w-full h-8 border max-w-[100px] rounded-xl border-tertiary">
            <a
              href={`${routes.cart_change_url}?id=${item.key}&quantity=${item.quantity - 1}`}
              className="px-2"
              data-ajax-cart-quantity-minus
            >
              <span className="text-xs text-primary">
                <svg fill="#323D3E" width="12px" height="12px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                  <path d="M216,132H40a4,4,0,0,1,0-8H216a4,4,0,0,1,0,8Z" fill="#323D3E" />
                </svg>
              </span>
            </a>
            <input
              type="number"
              value={`${item.quantity}`}
              name="updates[]"
              data-ajax-cart-quantity-input={`${item.key}`}
              className="noscroll !pb-[2px] quantity-control-input !text-xs text-tertiary !bg-transparent !border-none"
              readOnly
            />
            <a
              href={`${routes.cart_change_url}?id=${item.key}&quantity=${item.quantity + 1}`}
              className="px-2"
              data-ajax-cart-quantity-plus
            >
              <i className={`icon icon-plus text-xs text-tertiary`} />
            </a>
          </div>
        </AjaxCartQuantity>


      </div>

      {
          ( item.product?.compare_at_price) && (
            <div className="flex gap-3">
              <span className="text-sm text-red-500 font-body">
                {currencySymbol}{(item.price / 100 * item.quantity).toFixed(2)}
              </span>
        
              <span className="text-sm line-through text-tertiary   font-body">
                  {currencySymbol}
                  {((item.product?.compare_at_price / 100) *
                    item.quantity).toFixed(2)}
              </span>
            </div>
          )
        }
          
        {!item.product?.compare_at_price && (
                <div className="hidden text-sm font-semibold font-body text-primary lg:block">
                {currencySymbol}{(item.price / 100 * item.quantity).toFixed(2)}
                </div>
        )}
  

    </>
  );
};
