import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import { default as ExtendedButton } from "@project-stories/elements/Button";
import { addToCart } from "frontend/js/useShopifyCart";
import bundle from "frontend/js/alpine/components/product/bundle";
import helpers from 'frontend/js/helpers';

export const ExtendedBundlePrice = ({ settings }) => {
  const [price, setPrice] = useState();
  const [priceRange, setPriceRange] = useState();
  const [discountedPrice, setDiscountedPrice] = useState();
  const { bundle_id, bundle_discount } = settings;

  const handlePrice = useCallback((event) => {
    console.log(event, "event");
    console.log(event.detail, "event details"); // Accessing event details
    if(event.detail?.product_id == bundle_id) {
        const currPrice = (Number(event.detail?.price) / 100).toFixed(2);
        setPrice(currPrice);
        const reducedNumber = Number(currPrice) - (Number(currPrice) * (Number(bundle_discount) / 100));
        setDiscountedPrice(Number(reducedNumber).toFixed(2));
        setPriceRange( {
          totalMax:  (Number(event.detail?.priceRange?.totalMax) / 100).toFixed(2),
          totalMin: (Number(event.detail?.priceRange?.totalMin) / 100).toFixed(2),
          discountedMax:  (Number(event.detail?.priceRange?.discountedMax) / 100).toFixed(2),
          discountedMin:  (Number(event.detail?.priceRange?.discountedMin) / 100).toFixed(2),
        });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("bundle-update", handlePrice);
    return () => {
      document.removeEventListener("bundle-update", handlePrice);
    };
  }, [handlePrice]);

  const currencySymbol = window.Alpine?.store('currency').getCurrencySymbol();
  return (
  <div>
    { price > 0 &&
    <div className="flex flex-row justify-between">
      <div className="flex items-center gap-3">
        <div className="font-semibold text-xl/7 font-body lg:text-2xl/7 text-[#BD5141]">
          {`${currencySymbol}${discountedPrice}`} 
        </div>
        <div
          className="font-semibold line-through text-black/50 text-base/7 font-body" 
        >       
        {`${currencySymbol}${price}`}
        </div>

        <div className=" font-body text-[#BD5141] discount_percent top text-base/7">
        {`${bundle_discount}% OFF (SAVE ${currencySymbol}${ (price - discountedPrice).toFixed(2)})`}
        </div>
      </div>
      <div>
      </div>
    </div>
    }
    { price == 0 && priceRange && (Number(priceRange?.totalMax) === Number(priceRange?.totalMin)) &&
    <div className="flex flex-row justify-between">
      <div className="flex items-center gap-3">
        <div className="font-semibold text-xl/7 font-body lg:text-2xl/7 text-[#BD5141]">
        {`${currencySymbol}${priceRange.discountedMin}`} 
        </div>
        <div
          className="font-semibold line-through text-black/50 text-base/7 font-body" 
        >       
             {`${currencySymbol}${priceRange.totalMin}`} 
        </div>

        <div className=" font-body text-[#BD5141] discount_percent top text-base/7">
        {`${bundle_discount}% OFF (SAVE ${currencySymbol}${ (priceRange.totalMax - priceRange.discountedMax).toFixed(2)})`}
        </div>
      </div>
      <div>
      </div>
    </div>
    }
    { price == 0 && priceRange && (Number(priceRange?.totalMax) !== Number(priceRange?.totalMin)) &&
       <div className="flex flex-row justify-between">
       <div className="flex items-center ">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row justify-between gap-6">
            <div className="font-semibold text-xl/7 font-body lg:text-2xl/7 text-[#BD5141]">
            {`${currencySymbol}${priceRange.discountedMin} - ${currencySymbol}${priceRange.discountedMax}`} 
            </div>
            <div
              className="font-semibold line-through text-black/50 text-base/7 font-body" 
            >       
            {`${currencySymbol}${priceRange.totalMin} - ${currencySymbol}${priceRange.totalMax}`} 
            </div>
          </div>
          <div className=" font-body text-[#BD5141] discount_percent top text-base/7">
            {`${bundle_discount}% OFF (SAVE ${currencySymbol}${ (priceRange.totalMax - priceRange.discountedMax).toFixed(2)})`}
          </div>
        </div>
       </div>
       <div>
       </div>
     </div>
    }
  </div>


  );
};

export default ExtendedBundlePrice;

