import * as React from "react";

const ExtendedImageComponent = ({
  imageUrl,
  alt = "",
  isLazy = true,
  classes = "",
  imageUrlSet = ""
}) => {
  return (
    <img
      src={imageUrl}
      srcSet={imageUrlSet}
      alt={alt}
      loading={isLazy ? "lazy" : undefined}
      className={classes}
      sizes={imageUrlSet && `(max-width: 480px) 400px, 
      (max-width: 768px) 600px, 
      (max-width: 1024px) 800px, 
      (max-width: 1400px) 1000px, 
      1200px`} 
    />
  );
};

export default ExtendedImageComponent;
