import React from "react";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

export const PromotionTile = ({ promotion }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const {
    link,
    featured_image,
    title,
    description,
    title_alignment,
    landscape_orientation,
    title_color,
    description_color,
    description_style,
    button_text,
    button_style,
    text_alignment,
    mobile_padding,
    desktop_padding,
    content_width_desktop,
    content_width_mobile,
    content_position,
    button_position
  } = promotion;


  return (
    <div className={`grid gap-4 relative ${landscape_orientation ? 'col-span-2' : ''}`}>
      <a href={link || '#'} className="block h-full cursor-pointer">
        <div className="h-full">
          {featured_image && (
            <ExtendedImage
              imageUrl={featured_image}
              alt={title}
              isLazy={true}
              classes="object-cover w-full h-full lg:absolute"
            />
          )}
          <div className={`absolute inset-0 flex flex-col ${content_position != 'justify-between' ? content_position : '' }`}>
            <div
              style={{ 
                maxWidth: isMobile ? `${content_width_mobile}px` : `${content_width_desktop}px`,
                padding: isMobile ? `${mobile_padding}px` : `${desktop_padding}px`
              }}
              className={`${text_alignment === 'center' ? 'mx-auto' : ''} ${content_position == 'justify-between' ? 'flex-col flex h-full' : '' }`}
            >
              {title && (
                <h3
                  className="font-semibold !text-[18px] lg:!text-[28px] font-display leading-[20px]  lg:leading-[30px] mb-1"
                  style={{ color: title_color, textAlign: title_alignment }}
                >
                  {title}
                </h3>
              )}
              {description && (
                <p
                  className={`font-medium !font-display ${description_style}  leading-[14px]  lg:leading-[22px]`}
                  style={{ color: description_color,  textAlign: text_alignment }}
                  dangerouslySetInnerHTML={{ __html: description }}
                >
                </p>
              )}
              {button_text && (
                <div
                  className={`flex ${button_position} ${content_position == 'justify-between' ? 'mt-auto' : '' }`}
                >
                  <a
                    href={link}
                    className={`button max-w-max hover:no-underline mt-6 lg:mt-3 ${button_style}`}
                  >
                    {button_text}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>  
      </a>
    </div>
  );
};

export default PromotionTile;