export default {
    name: "bundle",
    component() {
      return {
        total_price: 0,
        updateTotalPrice(price) {
       
            this.total_price = price;
            console.log(this.total_price);
        },
      };
    },
  };
  