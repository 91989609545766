import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import helpers from './helpers';

// const SITE_ID = 'f4sjfr';
const SITE_ID = window.theme.ssSiteId;
const SEARCHSPRING_API_BASE_URL = `https://${SITE_ID}.a.searchspring.io/api/search/search.json`;
const SEARCHSPRING_AUTOCOMPLETE_API_URL = `https://${SITE_ID}.a.searchspring.io/api/search/autocomplete.json`;
const SEARCHSPRING_SUGGEST_API_URL = `https://${SITE_ID}.a.searchspring.io/api/suggest/query`;
const SEARCHSPRING_TRENDING_API_URL =`https://${SITE_ID}.a.searchspring.io/api/suggest/trending`;
const SEARCHSPRING_RECS_API_URL = `https://${SITE_ID}.a.searchspring.io/boost/${SITE_ID}/recommend`;


const getCommonParams = () => ({
  userId: helpers.getSSCookieId('ssUserId'),
  sessionId: helpers.getSSCookieId('ssSessionIdNamespace'),
  pageLoadId: uuidv4(),
  domain: helpers.getDomain(),
  redirectResponse: 'full',
  resultsFormat: 'json',
  siteId: SITE_ID,
});

const fetchProductResults = async (query, filterString = "") => {
  try {
    const response = await axios.get(`${SEARCHSPRING_API_BASE_URL}${filterString}`, {
      params: {
        ...query,
        ...getCommonParams(),
      },
      headers: {
        'Accept': 'application/json',
      },
    });
    if(response.data?.merchandising?.redirect) {
      window.location.href = response.data?.merchandising?.redirect;
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching search results:', error);
    throw error;
  }
};

const fetchAutocompleteResults = async (query) => {
  try {
    const response = await axios.get(SEARCHSPRING_AUTOCOMPLETE_API_URL, {
      params: {
        ...query,
        ...getCommonParams(),
      },
      headers: {
        'Accept': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching autocomplete results:', error);
    throw error;
  }
};

const fetchSuggestResults = async (query) => {
  try {
    const response = await axios.get(SEARCHSPRING_SUGGEST_API_URL, {
      params: {
        ...query,
        ...getCommonParams(),
      },
      headers: {
        'Accept': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching suggest results:', error);
    throw error;
  }
};

const fetchTrendingResults = async () => {
  try {
    const response = await axios.get(SEARCHSPRING_TRENDING_API_URL, {
      params: {
        ...getCommonParams(),
      },
      headers: {
        'Accept': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching trending results:', error);
    throw error;
  }
};

const fetchRecommendationResults = async (productId, tags) => {
  try {
    const response = await axios.get(SEARCHSPRING_RECS_API_URL, {
      params: {
        product: productId,
        tags,
        ...getCommonParams(),
      },
      headers: {
        'Accept': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching trending results:', error);
    throw error;
  }
}

export { fetchProductResults, fetchAutocompleteResults, fetchSuggestResults, fetchTrendingResults, fetchRecommendationResults };
