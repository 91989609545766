import React, { useState, useEffect } from "react";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

export const ExtendedBanner = ({ banners, section, useCarousel = true }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const { image_height_type, image_height, image_height_type_mobile, image_height_mobile } = section;
  const imageHeight = isMobile ? image_height_mobile : image_height;
  const definedHeight = isMobile ? image_height_type_mobile == 'defined' : image_height_type == 'defined';
  const openPreezieGuide = () => {
    if (!PREEZIE_GUIDE) {
      console.log('PREEZIE_GUIDE not found')
      return;
    }
      
    PREEZIE_GUIDE.openPrzCtaGuide()
  };

  return (
    <div className={`banner ${definedHeight ? `h-[${imageHeight}px]` : ''}`}>
      <div
        className={` ${definedHeight ? `h-[${imageHeight}px]` : 'banner__carousel'}`}
        {...(useCarousel && {
          'x-data': "carousel",
          'x-init': "$nextTick(() => { initialise(true, false, false) })",
          'x-ref': "carousel",
        })}
      >
        {banners.map((blockItem, index) => {
          const [firstPosition, lastPosition] = isMobile ? blockItem.settings.content_position_mobile.split("_") : blockItem.settings.content_position.split("_");
          const spaceBetween = `${firstPosition}_${lastPosition}` == 'space_between';
          const cta_alignment = isMobile ?  blockItem.settings.cta_alignment_mobile : blockItem.settings.cta_alignment;
          const text_alignment = isMobile ? blockItem.settings.text_alignment_mobile : blockItem.settings.text_alignment;
          let imageUrl =
            blockItem.settings.image?.src ?? blockItem.settings.image;
          if (isMobile) {
            imageUrl = blockItem.settings.mobile_image
              ? blockItem.settings.mobile_image
              : blockItem.settings.image?.src ?? blockItem.settings.image;
          }

          return (
            <div key={index}>
              {blockItem.type === "block_image" && (
                <>
                  <div className={`banner__overlay ${!spaceBetween ? `banner__overlay--${firstPosition} banner__overlay--${lastPosition}`: 'banner__overlay--center'}`}>
                    <div
                      className={`banner__overlay--content banner__overlay--text-${text_alignment} ${spaceBetween ? `h-full !justify-between`: ''}`}
                      style={{
                        width: isMobile ? `${blockItem.settings.mobile_content_width}px` : `${blockItem.settings.desktop_content_width}px`,
                        padding: isMobile ? `${blockItem.settings.mobile_padding}px` : `${blockItem.settings.desktop_padding}px`
                      }}
                    >
                      <p
                        className="font-bold h1"
                        style={{ color: `${blockItem.settings.title_color}` }}
                        dangerouslySetInnerHTML={{ __html: blockItem.settings.title }}
                      >
                      </p>
                      <p
                        className={`${blockItem.settings.subtext_style}`}
                        style={{ color: `${blockItem.settings.subtext_color}` }}
                        dangerouslySetInnerHTML={{ __html: blockItem.settings.subtext }}
                      >
                      </p>
                      {blockItem.settings.button_text && (
                        <div className={`button-wrapper flex banner__overlay--${cta_alignment}`}>
                          {blockItem.settings.is_quiz_trigger ? (
                            <a
                              className={`quiz-button button max-w-max hover:no-underline mt-6 lg:mt-3 ${blockItem.settings.button_style} ${blockItem.settings.show_button_on_desktop === false ? 'block lg:!hidden' : 'block'}`}
                              onClick={() => openPreezieGuide()}
                              style={{ cursor: 'pointer' }}
                            >
                              {blockItem.settings.button_text}
                            </a>
                          ) : (
                            <a
                              href={blockItem.settings.button_url}
                              className={`button max-w-max hover:no-underline mt-6 lg:mt-3 ${blockItem.settings.button_style} ${blockItem.settings.show_button_on_desktop === false ? 'block lg:!hidden' : 'block'}`}
                            >
                              {blockItem.settings.button_text}
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`w-screen ${definedHeight ? `h-[${imageHeight}px]` : 'h-[424px]'} banner__overlay--image content-over-media`}>
                    {blockItem.settings.button_url ||
                    blockItem.settings.button_text ? (
                      <div
                        className="absolute top-0 left-0 w-full h-full"
                        style={{
                          backgroundImage: `url(${imageUrl})`,
                          backgroundSize: "cover",
                          backgroundPositionY: "center",
                          backgroundPositionX: "center",
                        }}
                      ></div>
                    ) : (
                      <div
                        className="absolute top-0 left-0 flex items-center justify-center w-full h-full"
                        style={{
                          backgroundImage: `url(${imageUrl})`,
                          backgroundSize: "cover",
                          backgroundPositionY: "center",
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExtendedBanner;