import React, { useState, useEffect, useRef } from "react";
import AlpineWidget from "@arctheme-components/AlpineWidget";
import { TextField } from "@arctheme-components/elements/TextField/TextField";
import { LoadingSpinner } from "@project-stories/elements/LoadingSpinner/LoadingSpinner";
import { CartActions } from "./MinicartActions/MinicartActions";
import { CartItems } from "./MinicartItems/MinicartItems";
import { CartTitle } from "./MinicartTitle/MinicartTitle";
import { CartTotals } from "./MinicartTotals/MinicartTotals";

import shopify from '../../../../js/shopify';

export const Minicart = ({ cartData, style = "drawer", settings, translations }) => {
  const [cart, setCart] = useState({
    items: cartData.items,
    item_count: cartData.item_count,
    items_subtotal_price: cartData.items_subtotal_price,
  });

  const showCartCountAtTop = false;
  const checkoutBtnsRef = useRef();
 
  useEffect(() => {
    async function fetchProducts(productIds) {
      const products = await shopify.getShopifyProductList(productIds);
      return products;
    }

    // Function to fetch the cart data
    const fetchCart = async (event) => {
      let cartDataResponse: any = null;
      window.dispatchEvent(
        new CustomEvent("show-spinner", {
          detail: {
            id: "cart-spinner",
          },
        }),
      );

      try {
        const response = await fetch(`/cart.js`);
        cartDataResponse = await response.json();

        if (cartDataResponse) {
          const productIdsList = [...new Set(cartDataResponse?.items.map((product) => product.product_id))];
          const products = await fetchProducts(productIdsList);
          const updatedItems = cartDataResponse?.items.map((line_item) => {
            const product = products.find((product) => line_item.product_id === product.id);
            return product
              ? {
                  ...line_item,
                  product: product
                }
              : line_item;
          });
          const updatedCart = {
            ...cartDataResponse,
            items: updatedItems
          };
          setCart(updatedCart);
        }
      } catch (error) {
        console.error("Error fetching cart:", error);
      }

      window.dispatchEvent(
        new CustomEvent("hide-spinner", {
          detail: {
            id: "cart-spinner",
          },
        }),
      );
    };

    // Fetch cart data initially
    fetchCart({ detail: {} });

    // Set up an observer for cart changes using the Liquid Ajax Cart JS library
    document.addEventListener("liquid-ajax-cart:request-end", fetchCart);
    document.addEventListener("fetch-api-cart:request-end", fetchCart);

    const additionalCheckoutBtns = document.querySelector('[data-additional-checkout-buttons]');
    
    if (additionalCheckoutBtns) {
      const clonedBtnsElement = additionalCheckoutBtns.cloneNode(true);
      clonedBtnsElement.classList.remove('minicart__additional-buttons--hidden');
      additionalCheckoutBtns.remove();

      if (checkoutBtnsRef.current) {
        checkoutBtnsRef.current.appendChild(clonedBtnsElement);
      }
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("liquid-ajax-cart:request-end", fetchCart);
      document.removeEventListener("fetch-api-cart:request-end", fetchCart);
    };
  }, []);

  return (
    <>
      <div
        className={`minicart-wrapper ${style} relative`}
        x-cloak="true"
        x-transition:enter="transition ease-out duration-300"
        x-transition:enter-start="off-screen-right"
        x-transition:enter-end="right-50"
        x-transition:leave="transition ease-in duration-300"
        x-transition:leave-start="right-50"
        x-transition:leave-end="off-screen-right"
        x-show="$store.global.isMinicartVisible"
        {...{
          "x-on:click.outside": `$store.global.isMinicartVisible = false`,
        }}
      >
        <div className={"minicart w-full md:max-w-[450px]"}>
          <LoadingSpinner id={"cart-spinner"} />
          <div className="flex-none">
            <CartTitle item_count={cart.item_count} />
            {showCartCountAtTop && <CartCount item_count={cart.item_count} />}
          </div>
          <div className="flex-1 overflow-y-auto">
            <CartItems
              items={cart.items}
              show_vendor={false}
            />

            {cart.items.length <= 0 && (
              <div
                className={
                  "flex flex-col gap-y-[16px] text-center h-full justify-center p-[16px]"
                }
              >
                <div className={"b2 text-tertiary"}>
                  {settings.empty_text_one}
                  <br />
                  {settings.empty_text_two}
                </div>
                <a
                  x-on:click="$store.global.toggleMinicart()"
                  className={"minicart-empty__button button btn-primary"}
                >
                  {settings.empty_button}
                </a>
              </div>
            )}
          </div>
          {cart.items.length > 0 && (
            <div className="flex-none px-4 pb-6 border-t border-tertiary/30">
              <CartTotals
                cart_subtotal={cart.items_subtotal_price / 100}
                cart_items={cart.items}
                translations={translations}
              />
              <CartActions show_cart={true} />

              {/* Render additional checkout buttons */}
              {/* <div
                data-additional-checkout-buttons-minicart
                ref={checkoutBtnsRef}
              >
              </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const CartCount = ({ item_count }) => {
  return (
    <div className="item-count-message b2 bg-[#F5F5F5] p-[24px] flex gap-x-[8px]">
      <span className={"b-characteristics text-[#99A8B2]"}>Total Items</span>
      <span className="b-characteristics text-[#00263E]">{item_count}</span>
    </div>
  );
};

const CartNotes = ({ show = false }) => {
  return (
    <>
      <div className="cart_notes">
        <TextField
          type={"floating-input"}
          placeholder={"Enter notes for cart"}
          id={"cart_note"}
          value={undefined}
        />
      </div>
    </>
  );
};