import * as React from "react";
import { clsx } from "clsx";
import { useEffect, useState } from "react";
import Alpine from "alpinejs";

const MobileMenu = ({ settings, linkList }) => {
  const menuLinks = linkList[0]?.links;
  const [menuState, setMenuState] = useState({
    menuAnimClass: "",
    isMenuVisible: false,
  });

  const updateMenuState = (updates) =>
    setMenuState((prevState) => ({ ...prevState, ...updates }));

  const dispatchCustomEvent = (eventName, detail = { message: "" }) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  const menuItemClicked = (dataId) => {
    updateMenuState({ menuAnimClass: "slide-out-left" });
    Alpine.store("navigation").setMenuId(`header-${dataId}`);
  };

  const handleSubMenuOpen = () => {
    updateMenuState({ menuAnimClass: "slide-out-left", isMenuVisible: true });
  };

  const handleSubMenuClose = () => {
    updateMenuState({ menuAnimClass: "slide-in-left", isMenuVisible: true });
    onMenuClick();
    dispatchCustomEvent("megamenu-close");
  };

  const onMenuClick = () => {
    updateMenuState({ menuAnimClass: "slide-in-left", isMenuVisible: true });
    dispatchCustomEvent("mobile-menu-open");
  };

  const onCloseClick = () => {
    updateMenuState({ menuAnimClass: "slide-out-left", isMenuVisible: false });
    dispatchCustomEvent("toggle-display-close", { message: "header-account" });
    dispatchCustomEvent("mobile-menu-closed");
    dispatchCustomEvent("megamenu-close");
  };

  useEffect(() => {
    window.addEventListener("megamenu-menu-close", handleSubMenuClose);
    window.addEventListener("megamenu-menu-open", handleSubMenuOpen);
    return () => {
      window.removeEventListener("megamenu-menu-close", handleSubMenuClose);
      window.removeEventListener("megamenu-menu-open", handleSubMenuOpen);
    };
  }, []);

  return (
    <div
      className={`header__menu-icon ${
        menuState.isMenuVisible ? "relative top-[-5px]" : ""
      }`}
    >
      <div
        className={`menu-background ${clsx({
          "slide-in-left": menuState.isMenuVisible,
          "slide-out-left force-hidden": !menuState.isMenuVisible,
        })}`}
      ></div>
      <nav
        id="mobile-navigation"
        className={`header__icon-wrapper ${clsx({
          open: menuState.isMenuVisible,
          closed: !menuState.isMenuVisible,
        })}`}
        role="navigation"
        aria-label="Mobile Navigation Overlay"
      >
        <span
          aria-label="Mobile Navigation Button"
          aria-controls="mobile-menu-list"
        >
          {!menuState.isMenuVisible ? (
            <span
              aria-hidden="true"
              onClick={onMenuClick}
            >
              <i className="text-3xl icon-mobile-menu"></i>
            </span>
          ) : (
            <span
              aria-hidden="true"
              onClick={onCloseClick}
            >
              <i className="icon-close text-accent bg-tertiary p-2.5 text-[10px]"></i>
            </span>
          )}
        </span>
      </nav>

      <div id="mobile-menu-list"
        className={`mobile-nav ${menuState.isMenuVisible ? "open" : ""}`}
        style={{ backgroundColor: settings.section.mobile_nav_color }}
      >
        <div className="flex-grow overflow-x-hidden overflow-y-auto">
          <ul>
            {settings.section.featured_collection_list && (
              <div
                className="pt-[14px] pb-[2px]"
                style={{ backgroundColor: settings.section.featured_collection_bg }}
              >
                <h5 className="!text-base mx-3 pb-[14px]">
                  {settings.section.featured_collection_title}
                </h5>
                <div className="flex mb-1 ml-3 overflow-x-auto hide-scrollbar">
                  <ul className="flex space-x-5">
                    {settings.section.featured_collection_list.map(
                      (collection, index) => (
                        <li
                          key={index}
                          className={`flex-shrink w-20 group ${
                            index ===
                            settings.section.featured_collection_list.length - 1
                              ? "!mr-3"
                              : ""
                          }`}
                        >
                          <a
                            href={`/collections/${collection.handle}`}
                            className="block no-underline cursor-pointer hover:no-underline"
                          >
                            {settings.collection_list[collection.handle]?.image && (
                              <img
                                src={settings.collection_list[collection.handle]?.image}
                                alt={collection.title}
                                className="w-full h-auto mb-3"
                                loading={"lazy"}
                              />
                            )}
                            <label className="text-sm/[18px] font-semibold block lg:text-base/[18px] group-hover:text-highlight">
                              {collection.title}
                            </label>
                            <span className="text-xs font-semibold text-tertiary">
                              {settings.collection_list[collection.handle]?.stage}
                            </span>
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            )}

            {menuLinks.map((link, id) => (
              <li
                key={id}
                className="mobile-nav__item"
                onClick={() =>
                  menuItemClicked(link.title.toLowerCase().replace(" ", "-"))
                }
              >
               <a
                  href={link.url}
                  className="block text-base font-semibold capitalize hover:no-underline"
                  style={
                    link.title.toLowerCase() === "sale"
                      ? { color: settings.section.sale_color }
                      : {}
                  }
                >
                  {link.title}
                </a>
                {link.url === "#" && (
                  <span className="icon-chevron-right"></span>
                )}
              </li>
            ))}

            {settings.section.featured_product_list && (
              <div>
                <div className="flex items-center justify-between mx-3 border-t border-black-08">
                  <h5 className="!text-base mb-[14px] pt-[14px]">
                    {settings.section.featured_product_title}
                  </h5>
                  <a
                    href={settings.section.featured_product_button_url}
                    className="text-base font-normal underline"
                  >
                    {settings.section.featured_product_button_text}
                  </a>
                </div>
                <div className="flex mb-5 ml-3 overflow-x-auto hide-scrollbar">
                  <ul className="flex space-x-3">
                    {settings.section.featured_product_list.map(
                      (product, index) => (
                        <li
                          key={index}
                          className={`flex-shrink w-[115px] group ${
                            index ===
                            settings.section.featured_product_list.length - 1
                              ? "!mr-3"
                              : ""
                          }`}
                        >
                          <a
                            href={`/products/${product.handle}`}
                            className="block no-underline cursor-pointer hover:no-underline"
                          >
                            {product?.featured_image && (
                              <img
                                src={product.featured_image}
                                alt={product.title}
                                className="w-full h-auto mb-3"
                                loading={"lazy"}
                              />
                            )}
                            <label className="block font-semibold text-xs/4 group-hover:text-highlight">
                              {product.title}
                            </label>
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            )}

            <li className="mobile-nav__item">
              <a
                href={settings.section.contact_us_link}
                className="text-base font-semibold hover:no-underline"
              >
                {settings.section.show_contanct_icon && (
                  <i className="mr-3 icon-phone"></i>
                )}
                <span className="hover:no-underline">
                  {settings.section.contact_us_label}
                </span>
              </a>
            </li>
            <div className="px-3 py-4 mt-1 text-white bg-tertiary">
              <span className="mb-2.5 block text-secondary text-base font-semibold">
                {settings.section.newsletter_title}
              </span>
              <a
                href={settings.section.newsletter_button_link}
                className="button !border border-accent text-accent px-4 py-2 w-full hover:bg-[#F6F2EE] hover:text-primary hover:border-[#F6F2EE] hover:no-underline"
              >
                {settings.section.newsletter_button_text}
              </a>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
