import React, { useState, useEffect, useRef } from "react";
import { CartTableProduct } from "../CartTableProduct";
import { CartProductQuantity } from "../CartProductQuantity";
import {  updateItems } from "frontend/js/useShopifyCart";
import shopify from '../../../../../../js/shopify';

export const TableLayout = ({ settings }) => {
  const { cart: initialCart , section, routes } = settings;

  const [cart, setCart] = useState(initialCart);

    useEffect(() => {
      async function fetchProducts(productIds) {
        const products = await shopify.getShopifyProductList(productIds);
        return products;
      }
  
      // Function to fetch the cart data
      const fetchCart = async (event) => {
        let cartDataResponse: any = null;
        window.dispatchEvent(
          new CustomEvent("show-spinner", {
            detail: {
              id: "cart-spinner",
            },
          }),
        );
  
        try {
          const response = await fetch(`/cart.js`);
          cartDataResponse = await response.json();
  
          if (cartDataResponse) {
            const productIdsList = [...new Set(cartDataResponse?.items.map((product) => product.product_id))];
            const products = await fetchProducts(productIdsList);
            const updatedItems = cartDataResponse?.items.map((line_item) => {
              const product = products.find((product) => line_item.product_id === product.id);
              return product
                ? {
                    ...line_item,
                    product: product
                  }
                : line_item;
            });
            const updatedCart = {
              ...cartDataResponse,
              items: updatedItems
            };
            setCart(updatedCart);
          }
        } catch (error) {
          console.error("Error fetching cart:", error);
        }
  
        window.dispatchEvent(
          new CustomEvent("hide-spinner", {
            detail: {
              id: "cart-spinner",
            },
          }),
        );
      };
  
      // Fetch cart data initially
      fetchCart({ detail: {} });
  
      // Set up an observer for cart changes using the Liquid Ajax Cart JS library
      document.addEventListener("liquid-ajax-cart:request-end", fetchCart);
      document.addEventListener("fetch-api-cart:request-end", fetchCart);
      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener("liquid-ajax-cart:request-end", fetchCart);
        document.removeEventListener("fetch-api-cart:request-end", fetchCart);
      };
    }, []);
  

  async function handleRemove(line_item) {
    const updates = {}
    line_item.groupedItems.forEach(item => {
      updates[`${item.key}`] = 0;
    })

    await updateItems({ updates }, true, false);

  }


  const cartItems = cart.items;
  const productLayout = section.product_layout;

  const nonBundleItems = cartItems.filter(
    (item) => !item.properties._bundleId,
  );

  const bundleItems = cartItems.filter(
    (item) => item.properties._bundleId,
  ).reduce((acc, item) => {
    const bundleName = item.properties?._bundleName || null;
    const compiledItems = item.properties?._compiledItems || null;

    if (bundleName && compiledItems) {
        const groupKey = `${bundleName}|${compiledItems}`;

        if (!acc[groupKey]) {
            acc[groupKey] = {
                ...item,
                quantity: 0,
                groupedItems: []
            };
        }

        acc[groupKey].quantity += item.quantity;
        acc[groupKey].groupedItems.push(item);
    } else {
        acc[`${item.id}`] = item; 
    }

    return acc;
}, {});

const compiledBundleItems = Object.values(bundleItems);

  return (
    <div className="cart-table">
      {nonBundleItems.map((item, index) => {
        if (item.selling_plan_allocation) return null;
        return (
          <div key={index}>

              <div className="cart-table__line-item">
                <CartTableProduct item={item} />
                <CartProductQuantity item={item} settings={settings} />
                <div className="cart-table__line-item-action lg:text-center">
              
                  <button
                    data-ajax-cart-request-button={`/cart/change?id=${item.key}&quantity=0`}
                    className="px-0 w-auto h-auto min-h-[auto] text-sm underline normal-case text-tertiary font-body hover:text-highlight"
  
                  >
                    Remove
                  </button>
      
                 
                </div>
              </div>
          </div>
        );
      })}
      {compiledBundleItems.map((item, index) => {
        if (item.selling_plan_allocation) return null;
        return (
          <div key={index}>

              <div className="cart-table__line-item">
                <CartTableProduct item={item} bundle />
                <CartProductQuantity item={item} settings={settings} bundle />
                <div className="cart-table__line-item-action lg:text-center">
                  <button
                      className="px-0 w-auto h-auto min-h-[auto] text-sm underline normal-case text-tertiary font-body hover:text-highlight"
                      onClick={() => handleRemove(item)}
                    >
                      Remove
                  </button>
                </div>
              </div>
          </div>
        );
      })}
    </div>
  );
};
