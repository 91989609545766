import { useState, useEffect } from "react";

let cart = {
  items: [],
  item_count: 0,
};

let listeners = [];

const notifyListeners = () => {
  listeners.forEach((listener) => listener(cart));
};

export async function fetchCart(showMiniCart) {
    try {
      const response = await fetch(`/cart.js`);
      const cart = await response.json(); // Fetch and parse the cart data
  
      notifyListeners(); // Notify any listeners of the cart change
  
      // Dispatch the event only after cart.js is fully processed
      const event = new CustomEvent("fetch-api-cart:request-end", {
        detail: {
          requestState: {
            requestType: "fetch",
            responseData: { ok: response.ok },
          },
          cart, // Include the updated cart in the event
          showMiniCart
        },
      });
  
      document.dispatchEvent(event);
    } catch (error) {
      console.error("Failed to fetch cart:", error);
    }
  };

const requestCart = async (url, payload, action, fetchUpdatedCart = true, showMiniCart) => {
  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then(() => {
      if (fetchUpdatedCart) fetchCart(showMiniCart);
    })
    .catch((error) => console.error(`Failed to ${action}: `, error));
};

export const addToCart = async (payload, fetchUpdatedCart = true, showMiniCart = true) => {
  await requestCart("/cart/add.js", payload, "add item", fetchUpdatedCart, showMiniCart);
};

export const updateItem = async (payload, fetchUpdatedCart = true) => {
  await requestCart(
    "/cart/change.js",
    payload,
    "update item",
    fetchUpdatedCart,
  );
};

export const updateItems = async (payload, fetchUpdatedCart = true, showMiniCart = true) => {
  await requestCart(
    "/cart/update.js",
    payload,
    "update items",
    fetchUpdatedCart,
    showMiniCart
  );
};

export const useCart = (isMainCart) => {
  const [currentCart, setCurrentCart] = useState(cart);

  useEffect(() => {
    listeners.push(setCurrentCart);
    if (isMainCart) fetchCart();

    return () => {
      listeners = listeners.filter((listener) => listener !== setCurrentCart);
    };
  }, []);

  return {
    cart: currentCart,
  };
};
