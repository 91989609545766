import * as React from "react";
import { useState, useEffect } from "react";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import { default as ExtendedButton } from "@project-stories/elements/Button";
import { addToCart } from "frontend/js/useShopifyCart";
import shopify from "frontend/js/shopify";
import { Collapsible } from "@project-stories/elements/Collapsible/Collapsible";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import './ExtendedProductBundle.scss';

export const ExtendedBundle = ({ settings }) => {

  const { bundle_components , bundle_id, bundle_discount, bundle_title, bundle_url, bundle_variant_id, current_id } = settings;

  interface PriceRange {
    totalMin: String;
    totalMax: String;
    discountedMin: String;
    discountedMax: String;
  }

  function calculateInitialBundleRange(bundle_components: any): PriceRange {

    const bundlePrices =  bundle_components.map(bundle => {
        const products = bundle.sibling_products || [];
        
        // Extract min and max prices for each product
        const minPrices = products.map(product => parseFloat(product.variant_price_min));
        const maxPrices = products.map(product => parseFloat(product.variant_price_max));
    
        return {
            min: Math.min(...minPrices),
            max: Math.max(...maxPrices)
        };
    });
    

    const totalMin = bundlePrices.reduce((sum, bundle) => sum + bundle.min, 0).toFixed(2);
    const totalMax = bundlePrices.reduce((sum, bundle) => sum + bundle.max, 0).toFixed(2);

    const discountedMin = (totalMin - (totalMin * bundle_discount / 100)).toFixed(2);
    const discountedMax = (totalMax - (totalMax * bundle_discount / 100)).toFixed(2);
    
    return { totalMin, totalMax, discountedMin,  discountedMax };
  }


  const [hoveredLabel, setHoveredLabel] = useState({
    label: "",
    variant: ""
  });
  const isMobile = useMobileBreakpoint("1024px");
  const [hoveredVariant, setHoveredVariant] = useState({
    label: "",
    variant: ""
  });
  const [bundleImage, setBundleImage] = useState();

  const components = bundle_components.reduce((acc, item) => {
    acc[item.label] = item.sibling_products;
    return acc;
  }, {});

  const initialComponents = Object.fromEntries(
    Object.entries(components).map(([key, value]) => [key, value[0]])
  );

  const initialVariantsSelected = Object.fromEntries(
    Object.entries(components).map(([key, value]) =>{
      const availableVariant = value[0] && value[0].variants.find((variant) => variant.available == true)
      return (
        [key, 
          {
       
          }
          ]
      )
    })
  );

  const [activeColorVariants, setActiveColorVariants] = useState(initialComponents);
  const [activeVariantsIds, setActiveVariantsIds] = useState(initialVariantsSelected);
  const showAccordions = activeColorVariants && Object.entries(activeColorVariants).length > 2;

  const hasEmptyVariants = Object.entries(activeVariantsIds).some(([key, value]) => {
    return !value.id;
});

  useEffect(() => {
    let total_price = 0;

    let priceRange: PriceRange = {
      totalMin: '0',
      totalMax: '0',
      discountedMin: '0',
      discountedMax: '0'
    };

    if (hasEmptyVariants) {
      priceRange = calculateInitialBundleRange(bundle_components);
      total_price = 0
    }
    else {
      total_price = Object.values(activeVariantsIds).reduce((total, item) => total + Number(item.price), 0)
      priceRange = {
        totalMin: '0',
        totalMax: '0',
        discountedMin: '0',
        discountedMax: '0'
      };
    }
    
    const uniqueId = `id_${Date.now()}_${Math.random().toString(36).substr(2, 9)}_${bundle_id}`;   
    const timeout = setTimeout(() => {
      const event = new CustomEvent("bundle-update", {
        detail: {
          product_id: bundle_id,
          price: total_price,
          priceRange: priceRange,
          bundleDiscount: bundle_discount,
          bundleId: uniqueId,          
          bundleName: bundle_title,
          bundleImage: bundleImage,
          bundleURL: bundle_url,
          variantIds: activeVariantsIds,
          disableAdd: hasEmptyVariants,
          parentBundleId:  current_id
        },
      });
      document.dispatchEvent(event);
    }, 100);
    return () => clearTimeout(timeout);
  }, [bundle_id, activeVariantsIds, bundleImage]);

  useEffect(()=> {
    const fetchProducts = async () => {
      const shopifyProducts = await shopify.getShopifyProductList([bundle_id]);
      setBundleImage(shopifyProducts[0]?.featuredImage?.src)
    }
    
    fetchProducts()

  }, [])

  const renderOptions = (options, product_name) => {
    return (
      <div>
        <div className="text-xs font-semibold font-body mb-2">
          <span className="text-primary">Size</span>
          {hoveredVariant.variant == product_name && <span className="ml-3 text-tertiary lg:ml-4">{hoveredVariant.label}</span>}
          {hoveredVariant.variant != product_name  && <span className="ml-3 text-tertiary lg:ml-4">{activeVariantsIds[product_name]?.label}</span> }
        </div>
        <div className="flex flex-wrap items-center gap-3"> 
            {
              options && options.map((option, index) => {
                const optionLabel =  option.title?.split("/")[option.title?.split("/").length - 1];
                return (
                  <div className=""> 
                    <button
                    disabled={!option.available}
                    className={`min-h-10 h-10 px-3 py-2 font-semibold rounded-lg text-xs font-body lg:rounded-xl
                        ${option.id === activeVariantsIds[product_name].id ? "!bg-[#A87C64] !text-[#FDFCFC]": ""}
                      ${option.available ? " bg-darker text-primary cursor-pointer" : "bg-darker text-primary cursor-not-allowed opacity-50"}
                  
                    `}
                    onClick={() => setActiveVariantsIds(
                      {
                      ...activeVariantsIds,
                      [product_name]: { id: option.id, price: option.price, label: option.title?.split("/")[option.title?.split("/").length - 1] }
                      }
                    )}
                    
                    onMouseEnter={() => setHoveredVariant(
                      {
                        label: optionLabel,
                        variant: product_name
                      }
                    )}
                    onMouseLeave={() => setHoveredVariant(
                      {
                        label: "",
                        variant: ""
                      }
                    )}

                    >
                        {optionLabel}
                    </button>
                  </div>
                )
              })
            }
        </div>
      </div>
    );
  }

  const renderSwatchCategory = (swatches, product_name) => {
    return(
      <div className="flex flex-nowrap lg:flex-wrap items-center gap-2 mb-2">
      {swatches.map((swatch, index) => {
        const isActive =  activeColorVariants[product_name].label == swatch.label;
        const swatchLabel = `${swatch.label?.split("|")[0]}`;
      
        return (
      
            <button
              key={index}
              className="flex items-center !px-0 h-[30px]"
              onClick={() => setActiveColorVariants(
                  {
                  ...activeColorVariants,
                  [product_name]: {...swatch}
                  }
              )}
              onMouseEnter={() => setHoveredLabel(
                {
                  label: swatchLabel,
                  variant: product_name
                }
              )}
              onMouseLeave={() => setHoveredLabel(
                {
                  label: "",
                  variant: ""
                }
              )}
            >
              {swatch.color_image ? (
                <ExtendedImage
                  imageUrl={swatch.color_image}
                  alt={`Swatch color for ${swatch.color}`}
                  isLazy={true}
                  classes={`w-[30px] h-[30px] rounded-full ${isActive ? 'border-2 border-primary' : ''}`}
                />
              ) : (
                <span
                  className={`flex items-center justify-center w-[30px] h-[30px]  rounded-full ${
                    isActive ? 'border-2 border-primary' : 'border-transparent'
                  }`}
                  style={{ backgroundColor: swatch.color }}
                  title={swatch.color}
                />
              )}
            </button>
      
        );
      })}
      </div> 
    )
  }

  const renderSwatches = (swatches, product_name) => {
    const heritageSwatches = swatches.filter(swatch => swatch.label?.includes("Heritage"));
    const limitedEditionSwatches = swatches.filter(swatch => swatch.label?.includes("Limited Edition"));
    const uncategorizedSwatches = swatches.filter(swatch => !swatch.label?.includes("Heritage") && !swatch.label?.includes("Limited Edition"));
    return (
      <div className="flex flex-col my-2">
        <div className="text-xs font-semibold font-body">
          <span className="text-primary">Print</span>
          {hoveredLabel.variant == product_name && <span className="ml-3 text-tertiary lg:ml-4">{hoveredLabel.label}</span>}
          {hoveredLabel.variant != product_name  && <span className="ml-3 text-tertiary lg:ml-4">{activeColorVariants[product_name]?.label?.split("|")[0]}</span> }
        </div>

        {uncategorizedSwatches.length > 0 && (
        <div className="flex flex-wrap items-center gap-2">
          {renderSwatchCategory(uncategorizedSwatches, product_name )}
        </div>
      )}

        {heritageSwatches.length > 0 && (
        <div className="">
          <div className="text-xs font-semibold font-body">Heritage</div>
          <div className="flex flex-wrap items-center gap-2">
          {renderSwatchCategory(heritageSwatches, product_name)}
          </div>
        </div>
      )} 

      {limitedEditionSwatches.length > 0 && (
        <div className="">
          <div className="text-xs font-semibold font-body">Limited Edition</div>
          <div className="flex flex-wrap items-center gap-2">
          {renderSwatchCategory(limitedEditionSwatches, product_name)}
          </div>
        </div>
      )}

      {renderOptions(activeColorVariants[product_name] && activeColorVariants[product_name].variants, product_name)}
      </div>
    )
  }

  const renderComponents = (swatches, product_name) => {
    const swatchImage = activeColorVariants[product_name] && activeColorVariants[product_name].image;
    const swatchTitle = activeColorVariants[product_name] && activeColorVariants[product_name].title;
    const swatchURL = activeColorVariants[product_name] && activeColorVariants[product_name].url;
    const swatchTog = activeColorVariants[product_name] && activeColorVariants[product_name].tog;

    return (
      <div className="flex flex-col gap-[18px]">
          <div className="flex flex-row gap-[18px]"> 
            <div className="flex relative">
                <ExtendedImage
                        imageUrl={swatchImage}
                        alt={`Swatch image`}
                        isLazy={true}
                        classes={`max-h-[136px] max-w-[144px]`}
                />
            </div> 
            <div className="flex flex-col w-full"> 
                <div className="flex items-center gap-2.5">
                  { 
                    swatchTog && swatchTog.map((tag) => {
                      return (
                        <span style={{ background: tag.color }} className={`rounded-[20px] py-1 px-2 text-white text-xs font-semibold font-body uppercase`}>
                          { tag.rating }
                        </span>
                      )
                    })
                  }
                </div>
                <div className="text-sm font-semibold font-body my-2">
                  {swatchURL == '' ?    
                    <span className="text-primary">
                        {swatchTitle}
                    </span> 
                    : 
                    <a href={`${swatchURL}`}> 
                      <span className="text-primary">
                        {swatchTitle}
                      </span> 
                    </a>
                    }
                
                </div>
                { !isMobile && <div> 
                    {renderSwatches(swatches, product_name)}  
                </div>}
            </div>
          </div>
          { isMobile && 
            <div> 
              {renderSwatches(swatches, product_name)}  
            </div>
          }
      </div>
    );
  };

  return (
    <> 
      <div className="py-6 px-[21px] border border-[#DFC7B0] rounded-3xl">
          <div className="text-base/[22px] font-semibold font-body mb-2 lg:text-xl">
              <span className="text-primary">
                  Customise your bundle
              </span>
          </div>
      
          {
            bundle_components.map((component, index)=> {
              const hasAvailableOptions = activeVariantsIds[component.label] && activeVariantsIds[component.label].id;
              return (
                <div className={`${ showAccordions ? 'border-[#DFC7B0] border-b' : ''}`}> 
                  { 
                    showAccordions && (
                      <Collapsible
                      key={index}
                      title={`${index+1}. ${component.label }`}
                      titleClass={`text-base/[22px] font-semibold font-body lg:text-xl  ${index == 0 ? '!pt-0 !pb-3' : '!py-3'}  ${!hasAvailableOptions ? "text-[#7B7B7B]": "" }`}
                      contentClass="bg-[#FCF9F3] rounded-lg"
                      openIcon="icon-chevron-down  text-[#323D3E]"
                      closeIcon="icon-chevron-down rotate-180 text-[#323D3E]"
                      iconColor={`#323D3E`}
                      active={index == 0}
                    >
                          { component.sibling_products.length > 0 && (
                                  <div className="mt-2 lg:mb-3 mb-3"> 
                                    {renderComponents(component.sibling_products, component.label)} 
                                  </div>
                            )}
                      </Collapsible>
                    )
                  }
                  {
                    !showAccordions && (
                      <> 
                        <div className={`text-base/[22px] font-semibold font-body lg:text-xl  ${!hasAvailableOptions ? "text-[#7B7B7B]": ""}`}>
                            {`${index+1}. ${component.label }`}
                        </div>
                        <div className="bg-[#FCF9F3] rounded-lg pt-3 pb-3"> 
                          { component.sibling_products.length > 0 && (
                                  <div className="mb-3"> 
                                    {renderComponents(component.sibling_products, component.label)} 
                                  </div>
                            )}
                        </div>
                      </>
                    )
                  }
                </div>
              )
            })
          }
      </div>
    </>
  );
};

export default ExtendedBundle;

